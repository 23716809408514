import React from "react";
import {
  CpModal,
  CpButton,
  constants,
  modalService,
} from "canopy-styleguide!sofe";

export function ErrorBoundary(opts) {
  if (typeof opts !== "object" || typeof opts.featureName !== "string") {
    throw new Error(
      "canopy-react-error-boundary decorator should be called with an opts object that has a featureName string. e.g. @ErrorBoundary({featureName: 'life'})"
    );
  }

  return function decorateComponent(Comp) {
    return class CanopyReactErrorBoundary extends React.Component {
      static displayName = `CanopyReactErrorBoundary(${opts.featureName})`;
      state = {
        caughtError: null,
        caughtErrorInfo: null,
      };
      render() {
        if (!this.state.caughtError) {
          if (opts.noStrictMode || !React.StrictMode) {
            return <Comp {...this.props} />;
          } else {
            return (
              <React.StrictMode>
                <Comp {...this.props} />
              </React.StrictMode>
            );
          }
        } else {
          return null;
        }
      }
      componentDidCatch(err, info) {
        if (info && info.componentStack) {
          err.extra = Object.assign(err.extra || {}, {
            componentStack: info.componentStack,
          });
        }

        setTimeout(() => {
          throw err;
        });

        this.setState({
          caughtError: err,
          caughtErrorInfo: info,
        });

        // Only show one application's error modal at a time
        if (!document.querySelector(".canopy-react-error-boundary-modal")) {
          modalService.render(
            ErrorBoundaryModal,
            { opts },
            { width: 490, className: "canopy-react-error-boundary-modal" }
          );
        }
      }
    };
  };
}

function ErrorBoundaryModal({ opts }) {
  return (
    <>
      <CpModal.Header
        title={`The ${opts.featureName} feature is having problems`}
      />
      <CpModal.Body>
        <div
          className="cps-card__body"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="https://cdn.canopytax.com/images/ErrorState.svg"
            style={{ width: "8rem", padding: "16px 0" }}
          />
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Looks like there was a problem
          </div>
          <div style={{ textAlign: "center" }}>
            If the error continues, chat with support via the help center or
            call{" "}
            <a href={`tel:${constants.canopyPhone}`}>{constants.canopyPhone}</a>
          </div>
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          className="cp-mr-8"
          onClick={() => window.history.back()}
        >
          Go back
        </CpButton>
        <CpButton btnType="flat" onClick={() => window.location.reload()}>
          Reload page
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
