import { sortBy, uniq } from 'lodash';
import * as Sentry from '@sentry/browser';

/*
 * If single-spa is available, we'll add the activeApps to each logged message/exception
 */
let singleSpa;
// if featureToggles is available, we'll add the toggle information to the logs
let featureToggles;

export function init() {
  setTimeout(() => {
    if (SystemJS.has(SystemJS.normalizeSync('single-spa'))) {
      SystemJS.import('single-spa')
        .then(sspa => {
          singleSpa = sspa;
        })
        .catch(ex => {
          console.error(
            `Cannot initialize sentry because could not import single-spa`
          );
          setTimeout(() => {
            throw ex;
          });
        });
    }
    SystemJS.import('feature-toggles!sofe').then(ft => (featureToggles = ft));
  });

  window.addEventListener('single-spa:routing-event', () => {
    if (singleSpa) {
      Sentry.configureScope(scope => {
        scope.setTag(
          'activeApps',
          stringifyMountedApps(singleSpa.getMountedApps())
        );
      });
    }
  });
}

export function sentryLoaded(scope) {
  setTimeout(() => {
    if (singleSpa) {
      scope.setTag(
        'activeApps',
        stringifyMountedApps(singleSpa.getMountedApps())
      );
    }
  });
}

export function addActiveApps(obj) {
  if (singleSpa) {
    obj.activeApps = stringifyMountedApps(singleSpa.getMountedApps());
  }
}

export function getFeatureToggleInformation() {
  if (featureToggles && featureToggles.getAllToggleInformationForSentry) {
    return featureToggles.getAllToggleInformationForSentry();
  }
}

export function getSingleSpaVersioningInformation(
  manifest = {},
  identifiedService = ''
) {
  const alwaysServices = ['app-loader-ui', 'fetcher'];
  let activeApps = [];
  if (singleSpa) {
    activeApps = singleSpa.getMountedApps();
  }

  const exceptionServices = [
    ...alwaysServices,
    ...activeApps,
    identifiedService,
  ];
  const versions = uniq(exceptionServices).reduce((acc, service) => {
    const url = manifest[service];
    if (url) {
      const hash = getVersionHashFromUrl(url);
      acc[service] = hash;
    }
    return acc;
  }, {});
  return versions;
}

function getVersionHashFromUrl(url) {
  const split = url.split('/');
  if (split.length - 2 >= 0) {
    return split[split.length - 2];
  } else {
    return url;
  }
}

function stringifyMountedApps(mountedApps) {
  return JSON.stringify(sortBy(mountedApps));
}
