export const blue = [
  'app-dashboard-ui',
  'calendar-ui',
  'communications-ui',
  'login-ui',
  'global-settings',
  'notifications-ui',
  'invite-team-member',
  'contact-menu',
  'contacts-ui',
  'data-mapping-ui',
  'dedupe-ui',
];

export const corona = [
  'end-user-forms-ui',
  'engagements-menu',
  'integrations-ui',
  'logic-ui',
  'sme-navbar',
  'sme-ui',
  'sme-root-config',
  'sme-version',
  'notices-ui',
  'transcripts-ui',
];

export const gold = [
  'billing-ui',
  'workflow-ui',
];

export const green = [
  'docs-ui',
  'document-editor-ui',
  //it appears that letter-generator-ui and letters-ui are the same, 
  //but sofe-inspector registers letters-ui
  'letter-generator-ui',
  'letters-ui',
  'signing-ui',
];

export const taxPrep = [
  'diagnostics-ui',
  'xml-download-parcel',
  'irs-mapping-ui',
  'tax-prep-ui',
];

export const red = [
  'engagements-ui',
  'notes-ui',
  'rich-text-ui',
  'tasks-ui',
];

export const infrastructure = [
  'canopy-admin-ui',
  'canopy-urls',
  'canopy-styleguide',
  'common-dependencies',
  'cp-client-auth',
  'feature-toggles',
  'fetcher',
  'mobile-root-config',
  'sentry-error-logging-ui',
  'sofe-inspector',
  'app-loader-ui',
  'node-bootstrap-server',
  'toast-service',
  'walkme', // not actually a sofe-service
  'primary-navbar',
  'client-portal-ui',
];
